import React from "react"

// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import DownloadsSection from "components/downloads-sections"
import { ExternalButtonLink } from "components/buttons"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;

.qrCode {
    display: ${props => (props.hideQRCode ? "none" : "block")};
	text-align: center; 
	margin-left: 0;

	${breakpoint.xs`
		text-align: left;
		margin-left: 30px;
	`}
}

.hrefPointer {
	font-size: 15px;
	cursor: pointer;
	margin-left: 30px;
	text-align: left;

	${breakpoint.medium`
		font-size: 17px;
	`}

    &:hover {
        color: ${colors.orange};
    }
}

b {
	font-weight: 600;
}

${ExternalButtonLink} {
	margin-bottom: 40px;
	width: 100%; 
  
	${breakpoint.small`
		width: auto; 
	`}

	&:hover {
		color: ${colors.white}!important;
	}
  }


`

class StiProviderEducation extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hideQRCode: true,
		}
	}

	toggleQRCode = () => {
		this.setState({ hideQRCode: !this.state.hideQRCode })
	}

	render = props => (

		<DownloadsSection
			id="sti-provider"
			data-filter="sexual-health" data-filter-type="devices" data-section="provider"
			style={{ paddingTop: "120px", paddingBottom: "25px" }}
			className="active"
		>
			<StyledContainer
				hideQRCode={this.state.hideQRCode}
			>
				<div className="block__content">
					<h3>Provider Education<span className="subtitle"> -- Resources for individuals who diagnose and treat patients</span></h3>
				</div>

				<div className="block">
					<div className="block__material">
						<h4>Clinical Resources</h4>
						<ul className="downloadsList">
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Specification-Sheet.pdf`} target="_blank" rel="noopener noreferrer">Specification Sheet</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Point-of-Care-Quick-Reference-Guide.pdf`} target="_blank" rel="noopener noreferrer">Quick Reference Guide</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Point-of-Care-Instructions-for-Use.pdf`} target="_blank" rel="noopener noreferrer">Instructions for Use</a>
							</li>
							<li>
								<a href={`/sexual-health-test/Visby-Medical-Sexual-Health-Vaginal-Specimen-Collection-Instructions-for-Use.pdf`} target="_blank" rel="noopener noreferrer">Vaginal Specimen Collection Instructions for Use</a>
							</li>
							<li>
								<a href={`/visby-university/sexual-health-test/Antibiotic-Stewardship_PL-000158-B.pdf`} target="_blank" rel="noopener noreferrer">Improving Antibiotic Stewardship</a>
							</li>
							<li>
								<a href={`/visby-university/sexual-health-test/Disease_FactSheets_Chlamydia_PL-000150_B.pdf`} target="_blank" rel="noopener noreferrer">Chlamydia Fact Sheet</a>
							</li>
							<li>
								<a href={`/visby-university/sexual-health-test/Disease_FactSheets_Gonorrhea_PL-000150_B.pdf`} target="_blank" rel="noopener noreferrer">Gonorrhea Fact Sheet</a>
							</li>
							<li>
								<a href={`/visby-university/sexual-health-test/Disease_FactSheets_Trichomoniasis_PL-000150_B.pdf`} target="_blank" rel="noopener noreferrer">Trichomoniasis Fact Sheet</a>
							</li>
						</ul>
						<h4>Articles</h4>
						<ul className="downloadsList">
							<li>
								<a href={`/visby-university/sexual-health-test/Lancet-Summary.pdf`} target="_blank" rel="noopener noreferrer"><b>Article summary:</b> Clinical Performance (The Lancet)</a>
							</li>
							<li>
								<a href="https://journals.lww.com/stdjournal/Fulltext/2022/04000/Clinical_Integration_of_a_Highly_Accurate.3.aspx" target="_blank" rel="noopener noreferrer"><b>Article summary:</b> Clinical Integration (Sex Trans Dis J)</a>
							</li>
						</ul>
					</div>
					<div className="block__material">
						<h4>Auxiliary Resources</h4>
						<ul className="downloadsList">
							<li>
								<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10042575/" target="_blank" rel="noopener noreferrer"><b>Article:</b> Vaginal Swabs vs Urine (Ann Fam Med)</a>
							</li>
							<li>
								<a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10112678/pdf/jiad047.pdf" target="_blank" rel="noopener noreferrer"><b>Article:</b> Quality of Life Years Lost to STIs (J. Inf Dis)</a>
							</li>
							<li>
								<a href="https://bmcinfectdis.biomedcentral.com/articles/10.1186/s12879-023-08434-2" target="_blank" rel="noopener noreferrer"><b>Article:</b> Outpatient STI testing and treatment patterns in the United States: a real-world database study (BMC Inf Dis)</a>
							</li>
							<li>
								<a href="https://www.cdc.gov/std/treatment-guidelines/pocket-guide.pdf" target="_blank" rel="noopener noreferrer">CDC Treatment Guidelines Summary</a>
							</li>
							<li>
								<a href="https://www.cdc.gov/std/treatment-guidelines/provider-resources.htm#MobileApp" target="_blank" rel="noopener noreferrer">CDC Treatment Guidelines Mobile App</a>
								<button
									onClick={this.toggleQRCode}
									onKeyDown={this.toggleQRCode}
									className="hrefPointer"
								>
									Show QR Code for Mobile App
								</button>
							</li>
							<li className="qrCode">
								<StaticImage
									src="../../../assets/images/visby-university/sexual-health-test/cdc-treatment-qrcode.png"
									width={110}
									alt="CDC Treatment Guidelines Mobile App"
									className="thumbnail_image"
								/>
							</li>
						</ul>
					</div>
				</div>
				<ExternalButtonLink href="https://doodle.com/bp/alliepriego/visby-provider-meetingallie-priego" target="_blank" rel="noopener noreferrer" backgroundcolor="orange">Schedule Provider Training</ExternalButtonLink>
			</StyledContainer>
		</DownloadsSection>
	)
}



export default StiProviderEducation
